<template>
  <div class="simulation-exam-page">
    <ContentSidebarLayout>
      <div slot="main-content" class="main-content">
        <div class="main-content__header">
          <div class="header__right-side">
            <CountdownTimer
              data-test-id="timer"
              :time-in-minutes="remainTime / 60"
              class="right-side__timer"
              @time-out="showTimeOutModal"
            ></CountdownTimer>
          </div>
        </div>
        <AnswerSheetItem
          ref="exerciseRef"
          v-model="selectedAnswerId"
          class="main-content__answer-sheet-item"
          :answer-sheet-id="answerSheetId"
          :answer-sheet-item-id="answerSheetItemId"
          :answer-sheet-item-number="answerSheetItemNumber"
          :answers="answers"
          :exercise="exercise"
          :next-answer-sheet-item-id="nextAnswerSheetItemId"
          :previous-answer-sheet-item-id="previousAnswerSheetItemId"
          data-test-id="answer-sheet-item"
          @load-next-answer-sheet-item="loadNextAnswerSheetItem"
          @load-previous-answer-sheet-item="loadPreviousAnswerSheetItem"
        ></AnswerSheetItem>
        <TimeOutModalComponent v-model="isTimeOutModalVisible" data-test-id="time-out-modal" />
      </div>

      <div slot="sidebar" class="sliding-side-menu__bg">
        <div>
          <div class="sidebar__exam-title" data-test-id="exam-title">
            <span class="exam-title__area">{{ simulationExamArea }}</span>
            <span class="exam-title__name">{{ simulationExamName }}</span>
          </div>
          <div class="exam-title__border"></div>
          <NavigationPanel
            class="sidebar__navigation-panel"
            :subjects="navigationPanelSubjects"
            data-test-id="navigation-panel"
            @load-item="loadAnswerSheetItem"
          />
        </div>
      </div>
    </ContentSidebarLayout>
    <div v-show="isSlidingSideMenuVisible" class="sliding-side-menu">
      <SlidingSideMenu
        :is-open="isSlidingSideMenuOpen"
        data-test-id="sliding-side-menu"
        @transition-finished="updateSlidingSideMenuStatus(false)"
      >
        <div class="sliding-side-menu__sidebar">
          <div class="sidebar__exam-title" data-test-id="exam-title">
            <span class="exam-title__area">{{ simulationExamArea }}</span>
            <span class="exam-title__name">{{ simulationExamName }}</span>
          </div>
          <div class="exam-title__border"></div>
          <NavigationPanel
            class="sidebar__navigation-panel"
            :subjects="navigationPanelSubjects"
            data-test-id="navigation-panel"
            :is-mobile-resolution="isMobileResolution"
            @load-item="loadAnswerSheetItem"
          /></div
      ></SlidingSideMenu>
    </div>
  </div>
</template>

<script>
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import AnswerSheetItem from "@/use_cases/show_exam/AnswerSheetItemComponent.vue";
import NavigationPanel from "@/components/navigation_panel/NavigationPanel.vue";
import CountdownTimer from "@/components/countdown_timer/CountdownTimer.vue";
import ShowSimulationExam from "@/use_cases/show_exam/simulation_exam/ShowSimulationExam";
import TimeOutModalComponent from "@/use_cases/show_exam/simulation_exam/TimeOutModalComponent.vue";
import localForage from "localforage";
import ShowExam from "@/use_cases/show_exam/ShowExam";
import Store from "@/Store";
import SlidingSideMenu from "@/components/sliding_side_menu/SlidingSideMenu.vue";

const LAPTOP_WIDTH = 1200;
export default {
  name: "SimulationExamPage",
  components: {
    SlidingSideMenu,
    TimeOutModalComponent,
    CountdownTimer,
    NavigationPanel,
    AnswerSheetItem,
    ContentSidebarLayout,
  },
  beforeRouteLeave(to, from, next) {
    if (to !== from) {
      this.$emit("update-simulation-exam-menu-icon", false);
    }
    next();
  },
  props: {
    answerSheetId: {
      type: String,
      required: true,
    },
    answerSheetItemId: {
      type: String,
      required: true,
    },
    simulationExamId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      simulationExamName: "",
      simulationExamArea: "",
      navigationPanelSubjects: [],
      remainTime: 0,
      isTimeOutModalVisible: false,
      /** @type {{ text: string, imageUrl: string }} * */
      exercise: {},
      /** @type {{ id: number, text: string, imageUrl: string } []} * */
      answers: [],
      answerSheetItemNumber: 0,
      previousAnswerSheetItemId: null,
      nextAnswerSheetItemId: null,
      initialTimestamp: null,
      selectedAnswerId: null,
      store: Store,
      isSlidingSideMenuOpen: Store.isSimulationExamSlidingMenuEnabled,
      isSlidingSideMenuVisible: false,
      isMobileResolution: false,
    };
  },
  watch: {
    $route() {
      this.initializeData();
      this.scrollToTop();
    },
    store: {
      deep: true,
      handler() {
        if (Store.isSimulationExamSlidingMenuEnabled) {
          this.updateSlidingSideMenuStatus(true);
        }
        this.isSlidingSideMenuOpen = Store.isSimulationExamSlidingMenuEnabled;
      },
    },
  },
  async mounted() {
    await this.initializeData();
    this.emitUpdateSimulationExamMenuIcon();
    this.emitLoadFinished();
  },
  methods: {
    async initializeData() {
      this.initialTimestamp = Date.now();
      this.initializeSlidingSideMenu();
      await Promise.all([this.getSimulationExamNameAndArea(), this.getAnswerSheetItem(), this.loadNavigationPanel()]);
      this.refreshMathJax();
    },
    initializeSlidingSideMenu() {
      Store.disableSimulationExamSlidingMenu();
      window.addEventListener("resize", this.emitUpdateSimulationExamMenuIcon);
      this.isSlidingSideMenuOpen = Store.isSimulationExamSlidingMenuEnabled;
    },
    async getSimulationExamNameAndArea() {
      [this.simulationExamName, this.simulationExamArea] = await Promise.all([
        localForage.getItem("simulationExamName"),
        localForage.getItem("simulationExamArea"),
      ]);
    },
    async getAnswerSheetItem() {
      ({
        exercise: this.exercise,
        answers: this.answers,
        nextAnswerSheetItemId: this.nextAnswerSheetItemId,
        previousAnswerSheetItemId: this.previousAnswerSheetItemId,
        selectedAnswerId: this.selectedAnswerId,
        answerSheetItemNumber: this.answerSheetItemNumber,
      } = await ShowExam.getAnswerSheetItem({
        answerSheetId: this.answerSheetId,
        answerSheetItemId: this.answerSheetItemId,
      }));
    },
    refreshMathJax() {
      if (process.env.NODE_ENV !== "testing") {
        this.$nextTick(() => {
          window.MathJax.typesetPromise();
        });
      }
    },
    async loadNavigationPanel() {
      ({ remainTime: this.remainTime, subjects: this.navigationPanelSubjects } =
        await ShowSimulationExam.showSimulationExam({
          answerSheetId: this.answerSheetId,
          simulationExamId: this.simulationExamId,
        }));
    },
    emitLoadFinished() {
      this.$emit("load-finished");
    },
    async loadNextAnswerSheetItem() {
      await this.loadAnswerSheetItem(this.nextAnswerSheetItemId);
    },
    async loadPreviousAnswerSheetItem() {
      await this.loadAnswerSheetItem(this.previousAnswerSheetItemId);
    },
    async evaluateAnswerSheetItem() {
      await ShowExam.evaluateAnswerSheet({
        answerSheetId: this.answerSheetId,
      });
    },
    async loadAnswerSheetItem(answerSheetItemId) {
      if (this.selectedAnswerId !== null) {
        await this.saveAnswerSheetItem();
      }
      if (answerSheetItemId === null) {
        await this.evaluateAnswerSheetItem();
        await this.$router.push({
          name: "AnswerSheetResultsSummaryPage",
          params: {
            answerSheetId: this.answerSheetId,
          },
        });
      } else {
        await this.$router.push({
          name: "SimulationExamPage",
          params: {
            simulationExamId: this.simulationExamId,
            answerSheetId: this.answerSheetId,
            answerSheetItemId,
          },
        });
      }
    },
    async saveAnswerSheetItem() {
      await ShowExam.saveAnswerSheetItem({
        answerSheetId: this.answerSheetId,
        answerSheetItemId: this.answerSheetItemId,
        selectedAnswerId: this.selectedAnswerId,
        initialTimestamp: this.initialTimestamp,
      });
    },
    showTimeOutModal() {
      this.isTimeOutModalVisible = true;
    },
    updateSlidingSideMenuStatus(isVisible) {
      this.isSlidingSideMenuVisible = isVisible;
    },
    emitUpdateSimulationExamMenuIcon() {
      this.isMobileResolution = window.innerWidth < LAPTOP_WIDTH;
      this.$emit("update-simulation-exam-menu-icon", this.isMobileResolution);
    },
    async scrollToTop() {
      await this.$refs.exerciseRef.$el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_fonts.scss";

/** @define simulation-exam-page; */
.simulation-exam-page {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
}

/** @define main-content; weak */
.main-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @include laptop {
    justify-content: left;
  }
}

.main-content__answer-sheet-item {
  padding-top: 3em;

  @include laptop {
    padding-top: 1.5em;
  }
}

.main-content__exercise {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main-content__header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  top: 2.5em;
  left: 3%;

  @include laptop {
    padding-top: 1rem;
    top: 0;
    left: 0;
  }
}

.main-content__evaluate-btn {
  width: 100%;
  margin: 0.5em 0;
  flex-grow: 1;

  @include laptop {
    width: 70%;
    padding-bottom: 5em;
  }
}

.main-content__feedback {
  width: 100%;
}

.main-content__hints {
  width: 100%;

  @include laptop {
    display: none;
  }
}

.main-content__answers {
  margin: 3em 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > .exercise-answer {
    flex-grow: 20;
  }

  @include laptop {
    margin-bottom: 1em;
    width: 100%;
    justify-content: left;
    text-align: left;
  }
}

.main-content__floating-button {
  @include laptop {
    display: none;
  }
}

.main-content__hints_bottom_sheet {
  width: 100%;

  @include laptop {
    display: none;
  }
}

/** @define answers; */
.answers__item {
  @include laptop {
    padding-left: 2em;
  }
}

/** @define header; */
.header__right-side {
  width: 10%;
  position: relative;
  right: 7em;

  @include laptop {
    right: 5.5em;
  }
}

/** @define right-side; */
.right-side__timer {
  width: 5em;
}

/** @define sidebar; */
.sidebar__navigation-panel {
  width: 100%;
  height: 100%;
}

.sidebar__exam-title {
  @extend %font-subtitle-1;

  display: flex;
  flex-direction: column;
  color: $secondary-color;
  height: auto;

  @include laptop {
    padding: 1em 2em;
  }
}

/** @define exam-title; */
.exam-title__area {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 0;
  margin-bottom: 2rem;
  font-weight: $font-title-1-weight;

  @include laptop {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 50%;
  }
}

.exam-title__name {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  height: 0;
  font-weight: $font-title-2-weight;

  @include laptop {
    margin-bottom: 0;
    height: 50%;
  }
}

.exam-title__border {
  border: solid 0.2px white;
  margin: 1rem;
}

/** @define sliding-side-menu; */
.sliding-side-menu {
  position: absolute;
  z-index: 9;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  right: 0;
}

.sliding-side-menu__sidebar {
  padding-top: 2em;
  height: 100%;
  width: 95%;
  margin-right: 1em;
}

.sliding-side-menu__bg {
  margin-top: -2.5em;
  display: flex;
  flex-direction: column;
  height: calc(100% + 2.5em);
  width: calc(100% + 15px + 0.5em);
  background-color: $overlay-color;
}
</style>

<style>
/** @define main-content; weak */
.main-content__previous-btn > button {
  text-align: left;
}
</style>
