<template>
  <div>
    <div :class="'base-input-text base-input-text--' + styleModifier">
      <label for="base-input">
        <div class="base-input-text--container">
          <input
            id="base-input"
            :class="'base-input-text__input base-input-text__input--' + styleModifier"
            :minlength="minLength"
            :placeholder="placeholder"
            :required="required"
            :type="type"
            :value="value"
            :autofocus="autofocus"
            :disabled="disabled"
            @focusout="validateContent"
            @input="emitInputEvent"
          />
          <BaseInputCheckbox
            v-if="isCheckboxVisible"
            v-model="isPasswordVisible"
            :checkbox-icon-active="checkboxIconActive"
            :checkbox-icon-inactive="checkboxIconInactive"
            class="field__input-check"
          />
        </div>
      </label>
    </div>
    <span v-show="isErrorMessageVisible" :class="errorMessageClass" data-test-id="error-message">
      {{ errorMessage }}
    </span>
    <span
      v-for="validationErrorMessage in validationErrorMessages"
      v-show="isErrorsMessagesVisible"
      :key="validationErrorMessage"
      :class="errorMessageClass"
      data-test-id="validation-error-message"
    >
      {{ validationErrorMessage }}
    </span>
  </div>
</template>

<script>
import BaseInputCheckbox from "@/components/base_input_checkbox/BaseInputCheckbox.vue";
import Validatable from "../../mixins/Validatable.vue";

export default {
  name: "BaseInputText",
  components: { BaseInputCheckbox },
  mixins: [Validatable],
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    styleModifier: {
      type: String,
      default: "black",
    },
    minLength: {
      type: Number,
      default: 0,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
      validator: (prop) => ["text", "password", "email"].includes(prop),
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    isErrorMessageVisible: {
      type: Boolean,
      default: false,
    },
    isErrorsMessagesVisible: {
      type: Boolean,
      default: true,
      required: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    errorMessageType: {
      type: String,
      default: "default",
      required: false,
      validator: (prop) => ["default", "soft"].includes(prop),
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    isCheckboxVisible: {
      type: Boolean,
      default: false,
      required: false,
    },
    checkboxIconInactive: {
      type: String,
      default: "mdi-checkbox-blank-outline",
      required: false,
    },
    checkboxIconActive: {
      type: String,
      default: "mdi-checkbox-marked",
      required: false,
    },
  },
  data() {
    return {
      isPasswordVisible: false,
    };
  },
  computed: {
    errorMessageClass() {
      return this.errorMessageType === "soft" ? "error-message_soft" : "error-message";
    },
  },
  watch: {
    isPasswordVisible(newValue) {
      this.$emit("update:isPasswordVisible", newValue);
    },
  },
  methods: {
    emitInputEvent(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define base-input-text */
.base-input-text--container {
  display: flex;
}

.base-input-text {
  padding: 0.5em;
  width: auto;
  border-bottom: 0.1em solid $black;
}

.base-input-text__input {
  @extend %font-body-2;

  background: transparent;
  border: 0;
  width: 100%;
  outline: none;
}

.base-input-text--black {
  border-bottom: 0.1em solid $black;
}

.base-input-text--white {
  border-bottom: 0.1em solid $white;
}

.base-input-text__input--black {
  color: $black;
}

.base-input-text__input--white {
  color: $white;
}

.base-input-text__input--white::placeholder {
  color: $white;
}

/** @define error-message */
.error-message {
  @extend %font-body-2;

  color: $error-color;
  font-weight: bold;
  padding-top: 1em;
  text-align: center;
}

.error-message_soft {
  @extend %font-body-2;

  color: $error-text-color;
  font-weight: bold;
  padding-top: 1em;
}
</style>
