<template>
  <BaseCard class="product">
    <div class="product__title">
      <h3 class="product__name m-0" data-test-id="product-name">
        {{ product.name }}
      </h3>
    </div>

    <div class="product__body">
      <div class="product__section product__section--image p-1" data-test-id="product-image">
        <img class="product__image" :src="product.image" :alt="product.name" />
        <div class="product__price" data-test-id="product-price">
          <span>${{ product.price }}</span>
        </div>
      </div>
      <div class="product__section product__section--info p-2">
        <div>
          <p class="product__description" data-test-id="product-description">
            {{ product.description }}
          </p>
        </div>
      </div>
    </div>

    <div class="product__footer">
      <div class="product__buy-btn">
        <BaseButton
          style-modifier="secondary"
          :data-test-id="`buy-btn-${product.productId}`"
          @click="$emit('open-payment-method-modal', product)"
        >
          Comprar
        </BaseButton>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/base_card/BaseCard.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";

export default {
  name: "CoinsBundleCard",
  components: {
    BaseCard,
    BaseButton,
  },

  props: {
    /** @type {{ productId: int , name: string , description: string, price: int , image: string, type: string }} * */
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_typography.scss";
@import "~@/scss/_spacing.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_resolutions.scss";

/** @define product */
.product {
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-color: $secondary-color;
  border-width: 0.2em;
  justify-content: space-evenly;
  min-height: 20em;
}

.product__title {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.product__name {
  @extend %font-section-title-1;

  color: $black;
  text-align: justify;
}

.product__body {
  display: flex;
  flex-direction: row;
}

.product__section {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.product__section--info {
  flex-basis: fit-content;
  display: flex;
  flex-direction: column;
}

.product__image {
  max-width: 100%;
}

.product__description {
  @extend %font-body-2;

  text-align: left;
}

.product__price {
  @extend %font-section-title-1;

  color: $black;
  text-align: center;
}

.product__footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product__buy-btn {
  @extend %font-section-title-2;

  text-align: right;
  width: 50%;
}
</style>
