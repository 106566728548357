<template>
  <div class="container-bg">
    <div class="payment-method_pay">
      <CardImage :class="['payment-method_card', shadowCardClass]" @click="buyWith('card')"></CardImage>
      <OxxoImage :class="['payment-method_oxxo', shadowOxxoClass]" @click="buyWith('oxxo')"></OxxoImage>
    </div>
    <div class="card-close_container">
      <div class="oxxo-container-close_icon" data-test-id="close-btn-oxxo" @click="hidePaymentMethodModal">
        <CloseButtonImage class="bank-card-close_icon"></CloseButtonImage>
      </div>
    </div>
    <BaseCard class="container_card">
      <div class="oxxo-container_top">
        <OxxoImage class="oxxo_image"></OxxoImage>
        <div class="oxxo_title">Pago en OXXO</div>
      </div>
      <div class="oxxo-container_amount">
        <v-progress-circular v-if="showLoader" color="blue" indeterminate :size="50" :width="5"></v-progress-circular>
        <div v-if="isAmountLoading">
          <div class="oxxo-mount_text">
            Monto a pagar: <span class="oxxo_weight">${{ oxxoPaymentInfo.amountToPay }}</span>
          </div>
          <div class="oxxo-mount_text">
            Pagar antes del: <span class="oxxo_weight">{{ timestampToDate(oxxoPaymentInfo.expirationDate) }}</span>
          </div>
          <div class="oxxo-mount_info">
            <p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
          </div>
        </div>
      </div>
      <div class="oxxo-container_reference">
        <v-progress-circular v-if="showLoader" color="black" indeterminate :size="30" :width="3"></v-progress-circular>
        <div v-if="isAmountLoading">
          <div class="oxxo-reference_digit">{{ oxxoPaymentInfo.referenceNumber }}</div>
        </div>
        <div class="oxxo_line"></div>
        <div class="oxxo-reference_text">Número de referencia</div>
      </div>
      <div class="oxxo-container_instructions">
        <div>
          1. Acude a tu tienda OXXO más cercana e indica que quieres hacer un pago referenciado (OXXO PAY).
          <br />
          2. Dicta el número de referencia de esta ficha.
          <br />
          3. Realiza el pago (ya con la comisión incluida).
          <br />
          4. Recibirás un correo de confirmación en 24hrs. Guarda tu comprobante para cualquier tipo de aclaraciones.
        </div>
      </div>
      <BaseButton
        style-modifier="secondary"
        data-test-id="payment-reference-download-btn"
        class="payment-instructions__download-button"
        @click="getPaymentReferenceData"
      >
        Descargar Ficha
      </BaseButton>
    </BaseCard>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";
import DownloadOxxoPDF from "@/use_cases/buy_product/DownloadOxxoPDF";
import BaseCard from "@/components/base_card/BaseCard.vue";
import OxxoImage from "@/assets/oxxo.svg?inline";
import CardImage from "@/assets/card.svg?inline";
import CloseButtonImage from "@/assets/close_btn.svg?inline";

export default {
  name: "OxxoInstructionsModal",
  components: { BaseCard, BaseButton, OxxoImage, CardImage, CloseButtonImage },

  props: {
    /** @type {{ referenceNumber: string, amountToPay: float, expirationDate: date, barcodeUrl: string }} * */
    oxxoPaymentInfo: {
      required: true,
      type: Object,
    },
    isVisible: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isShadowCardActive: false,
      isShadowOxxoActive: true,
      isAmountLoading: false,
      showLoader: true,
    };
  },
  computed: {
    shadowCardClass() {
      return {
        "payment-method-shadow": this.isShadowCardActive,
      };
    },
    shadowOxxoClass() {
      return {
        "payment-method-shadow": this.isShadowOxxoActive,
      };
    },
  },
  watch: {
    oxxoPaymentInfo: {
      handler() {
        this.isAmountLoading = true;
        this.showLoader = false;
      },
      deep: true,
    },
  },
  methods: {
    async getPaymentReferenceData() {
      const { paymentReferenceData } = await DownloadOxxoPDF.DownloadOxxoPDFReference(this.oxxoPaymentInfo);
      const paymentReferenceFile = new File([paymentReferenceData], "ficha-de-pago-oxxo.pdf", {
        type: "application/pdf",
      });
      this.openFile(paymentReferenceFile);
    },
    openFile(file) {
      const fileAnchor = document.createElement("a");
      fileAnchor.href = URL.createObjectURL(file);
      fileAnchor.click();
    },
    hidePaymentMethodModal() {
      this.isShadowCardActive = true;
      this.isShadowOxxoActive = false;
      this.$emit("hide", false);
    },
    buyWith(method) {
      this.isShadowCardActive = method === "card";
      this.isShadowOxxoActive = method === "oxxo";
      this.$emit("buy-with", method);
    },
    timestampToDate(timestamp) {
      const date = new Date(timestamp * 1000);

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear() % 100;

      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";
@import "~@/scss/_fonts.scss";
@import "~@/scss/_spacing.scss";

.container-bg {
  color: $black;
  background: $white;
  padding: 0;
  height: 100%;

  @include laptop {
    padding: 0 2.5em;
  }
}

.card-close_container {
  display: none;

  @include laptop {
    display: flex;
    justify-content: flex-end;
    padding-top: 2em;
  }
}

.oxxo-container-close_icon {
  cursor: pointer;
}

.bank-card-close_icon {
  cursor: pointer;
  width: 2em;
  height: 2em;
}

.container_card {
  @extend %font-body-3;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: $black;
  padding-bottom: 2em;
  background: white;
  border-radius: 0;
  height: auto;
  border: none;

  @include laptop {
    border: solid 2px $secondary-color;
    margin-top: 2em;
    padding: 1.3em 0;
  }
}

.oxxo-container_top {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;

  @include laptop {
    display: flex;
  }
}

.oxxo_image {
  width: 7em;
}

.oxxo_title {
  @extend %font-h2_title;
}

.oxxo-mount_text {
  @extend %font-body-1;
}

.oxxo-mount_info {
  @extend %font-caption-3;

  font-style: italic;
}

.oxxo-container_amount {
  background-color: $secondary-background;
  border-radius: 0.4em;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  text-align: center;
  align-items: center;
  width: 100%;
  padding: 1em 0 0;

  @include laptop {
    padding: 1em;
    width: 80%;
  }
}

.oxxo_weight {
  font-weight: bold;
}

.oxxo-container_reference {
  margin-top: 1.3em;
}

.oxxo-reference_digit {
  @extend %font-title-2;
}

.oxxo-reference_text {
  @extend %font-caption-3;

  color: $overlay-color--hover;
}

.oxxo_line {
  border-bottom: 1px solid $black;
  margin: 0 auto;
  width: 100%;
}

.oxxo-container_instructions {
  @extend %font-caption-3;

  text-align: justify;
  padding: 1em 0;
  width: 100%;

  @include laptop {
    width: 80%;
  }
}

.payment-method_pay {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding-top: 1em;

  @include laptop {
    display: none;
  }
}

.payment-method_card {
  cursor: pointer;
  width: 3.5em;
}

.payment-method_oxxo {
  cursor: pointer;
  width: 4.5em;
}

.payment-method-shadow {
  border-radius: 8%;
  box-shadow: #0a0a0a 0 3px 13px 0;
}
</style>
