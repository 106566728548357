<template>
  <v-app-bar
    app
    hide-on-scroll
    height="45"
    scroll-behavior="hide elevate"
    class="course-bar"
    data-test-id="daily-exam-bar-button12312"
    @click="startDailyExam"
  >
    <v-toolbar-title class="course-bar__title">{{ activeCourseText }}</v-toolbar-title>
  </v-app-bar>
</template>

<script>
// eslint-disable-next-line import/extensions
import DailyExamUtils from "@/use_cases/show_subjects/daily_exam/DailyExamUtils";
import localForage from "localforage";
import GetActiveCourseProgressId from "@/use_cases/get_active_course_progress_id/GetActiveCourseProgressId";

export default {
  name: "CourseBar",
  data() {
    return {
      isDailyExamModelOpen: false,
      activeCourseText: "",
    };
  },
  watch: {
    async $route() {
      await this.updateActiveCourseText();
    },
  },
  async mounted() {
    await this.updateActiveCourseText();
  },
  methods: {
    async startDailyExam() {
      const response = await DailyExamUtils.startDailyExam(this.courseProgressId);
      await this.redirectToExamPage(response.answerSheetId, response.currentAnswerSheetItemId);
    },
    async redirectToExamPage(answerSheetId, answerSheetItemId) {
      await this.$router.push({
        name: "DailyExamPage",
        params: {
          answerSheetId,
          answerSheetItemId,
        },
      });
    },
    async updateActiveCourseText() {
      const courseName = await localForage.getItem("activeCourseName");

      if (courseName) {
        this.activeCourseText = courseName;
      } else {
        const response = await GetActiveCourseProgressId.getActiveCourseProgressId();
        await localForage.setItem("activeCourseName", response.courseName);
        await localForage.setItem("activeCourseProgressId", response.activeCourseProgressId);
        this.activeCourseText = response.courseName;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/typography";
@import "~@/scss/colors";

/** @define course-bar */
.course-bar {
  border-top: 2px solid white !important;
  margin-top: 65px !important;
  color: $white !important;
  display: flex !important;
  justify-content: center !important;
  background: $primary-color !important;
  z-index: 2 !important;

  &__title {
    @extend %font-body-1;

    cursor: default;
  }
}
</style>
