<template>
  <transition
    name="slide"
    @after-enter="showMenuContent"
    @before-leave="hideMenuContent"
    @after-leave="emitTransitionFinished"
  >
    <div v-show="isOpen" data-test-id="sliding-side-menu" class="sliding-side-menu">
      <slot v-show="isContentVisible" />
    </div>
  </transition>
</template>

<script>
export default {
  name: "SlidingSideMenu",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      isContentVisible: false,
    };
  },
  computed: {
    slidingSideMenuStyle() {
      return this.isOpen ? "sliding-side-menu--opened" : "sliding-side-menu--closed";
    },
  },
  watch: {
    isOpen() {
      return this.isOpen;
    },
  },
  methods: {
    showMenuContent() {
      this.isContentVisible = true;
    },
    hideMenuContent() {
      this.isContentVisible = false;
    },
    emitTransitionFinished() {
      this.$emit("transition-finished");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define sliding-side-menu; */
.sliding-side-menu {
  @extend %font-caption-2;

  background-color: $overlay-color;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  flex-wrap: initial;
  width: 30em;

  @include laptop {
    width: 15em;
  }
}
</style>

<style scoped lang="scss">
@import "~@/scss/_resolutions.scss";

.slide-enter-active,
.slide-leave-active {
  transition: max-width 0.5s;
  max-width: 100%;

  @include laptop {
    max-width: 15em;
  }
}

.slide-enter,
.slide-leave-to {
  max-width: 0;
  display: flex;
}
</style>
