<template>
  <div class="products">
    <ContentSidebarLayout :is-side-bar-visible="false">
      <div slot="main-content" class="products__list" data-test-id="products-list">
        <div class="list__bundle">
          <h2 class="bundle__title">Ofertas especiales</h2>
          <div v-for="product in offers" :key="product.id" class="bundle__item">
            <CoinsProduct
              :promotion-minutes-left="minutesLeft"
              :product="product"
              @time-out="timeOut"
              @open-payment-method-modal="openPaymentMethodModal"
            />
          </div>
        </div>
        <div class="list__bundle">
          <h3 class="bundle__title">Paquetes de monedas</h3>
          <div v-for="product in bundles" :key="product.id" class="bundle__item">
            <CoinsProduct
              :promotion-minutes-left="minutesLeft"
              :product="product"
              @time-out="timeOut"
              @open-payment-method-modal="openPaymentMethodModal"
            />
          </div>
        </div>
      </div>
    </ContentSidebarLayout>

    <div>
      <PaymentMethodModal
        data-test-id="payment-method-modal"
        :is-visible="isPaymentMethodModalOpen"
        :product="selectedProduct"
        :oxxo-payment-info="oxxoPaymentInfo"
        @hide-modal="closePaymentMethodModal"
        @buy-with="buyWith"
      />
    </div>
  </div>
</template>

<script>
import localForage from "localforage";
import ShowProducts from "@/use_cases/show_products/ShowProducts";
import BuyProduct from "@/use_cases/buy_product/BuyProduct";
import CoinsProduct from "@/components/coins_product/CoinsProduct.vue";
import PaymentMethodModal from "../buy_product/PaymentMethodModal.vue";
import ContentSidebarLayout from "../../components/ContentSidebarLayout.vue";

const COINS_BUNDLE = 1;
const THREE_MONTHS = 2;
const SIX_MONTHS = 3;
const SIX_MONTHS_DISCOUNT = 37;

export default {
  name: "ShowProductsPage",
  components: {
    CoinsProduct,
    ContentSidebarLayout,
    PaymentMethodModal,
  },

  data() {
    return {
      /**
       * @typedef Product
       * @type {object}
       * @property {number} productId
       * @property {string} name
       * @property {string} description
       * @property {number} price
       * @property {string} image
       * @property {string} type
       */

      /** @type {Product} * */
      selectedProduct: {},

      /** @type {Product[]} * */
      products: [],
      bundles: [],
      offers: [],

      isPaymentMethodModalOpen: false,

      oxxoPaymentInfo: {},
      bankPaymentInfo: {},

      minutesLeft: 0,
      initialTime: null,
      promotionTime: null,
      sixMonthsProduct: null,
      sixMonthsWithDiscount: null,
    };
  },

  async created() {
    this.COINS_BUNDLE = COINS_BUNDLE;
    this.THREE_MONTHS = THREE_MONTHS;
    this.SIX_MONTHS = SIX_MONTHS;
    this.SIX_MONTHS_DISCOUNT = SIX_MONTHS_DISCOUNT;
  },

  async mounted() {
    await this.requestShowProductsService();
    this.initialTime = await localForage.getItem("initialTime");
    this.promotionTime = await localForage.getItem("promotionTime");
    const now = new Date().getTime();
    if (this.promotionTime != null) {
      this.minutesLeft = (this.promotionTime - Math.round((now - this.initialTime) / 1000)) / 60;
    }
    this.sixMonthsWithDiscount = {
      productId: 37,
      name: "6 meses de Ingresapp Premium",
      description:
        // eslint-disable-next-line max-len
        "Acceso ilimitado durante seis meses a toda las funciones de Ingresapp. Recibiras 10 mil monedas para que las uses como prefieras.",
      price: 359,
      image: "https://ingresapp-api-new.s3.amazonaws.com/media/treasure.png",
      type: "coins_bundle",
    };
    if (this.minutesLeft > 0) {
      this.offers.push(this.sixMonthsWithDiscount);
    } else {
      this.offers.push(this.sixMonthsProduct);
    }

    this.offers.sort((a, b) => b.productId - a.productId);
  },

  methods: {
    async timeOut() {
      this.minutesLeft = 0;
      this.removeDiscountProducts();
    },
    removeDiscountProducts() {
      if (this.offers.length > 0) {
        const index = this.offers.findIndex((item) => item.id === this.SIX_MONTHS_DISCOUNT);
        if (index !== -1) {
          this.offers.splice(index, 1);
        }
        if (this.sixMonthsProduct != null) {
          this.offers.push(this.sixMonthsProduct);
        }
        this.offers.sort((a, b) => b.productId - a.productId);
      }
    },
    async requestShowProductsService() {
      this.products = await ShowProducts.getProducts();
      for (let index = 0; index < this.products.length; index += 1) {
        if ([this.THREE_MONTHS, this.SIX_MONTHS, this.SIX_MONTHS_DISCOUNT].includes(this.products[index].productId)) {
          if (this.products[index].productId === this.SIX_MONTHS) {
            this.sixMonthsProduct = this.products[index];
          } else {
            this.offers.push(this.products[index]);
          }
        } else {
          this.bundles.push(this.products[index]);
        }
      }

      this.$emit("load-finished");
    },
    openPaymentMethodModal(product) {
      this.selectedProduct = product;
      this.isPaymentMethodModalOpen = true;
    },
    closePaymentMethodModal() {
      this.isPaymentMethodModalOpen = false;
    },
    buyWith(method) {
      switch (method) {
        case "oxxo":
          this.buyWithOxxo();
          break;
      }
    },
    async buyWithOxxo() {
      const { productId } = this.selectedProduct;
      this.oxxoPaymentInfo = await BuyProduct.BuyProductWithOxxo({ productId });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_resolutions.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

/** @define products */
.products {
  width: 100%;
  height: 100%;
}

.products__list {
  padding-top: 1em;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  flex-direction: column;

  @include laptop {
    align-content: space-between;
    flex-direction: row;
  }
}

/** @define list */
.list__bundle {
  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  padding-bottom: 2em;

  @include laptop {
    width: 50%;
  }
}

/** @define bundle */
.bundle__item {
  width: 100%;
  padding: 2em;
  padding-bottom: 1em;

  @include laptop {
    margin-top: 0;
    margin-left: auto;
  }
}

.bundle__title {
  @extend %font-section-title-1;

  font-size: 1.5rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
