<template>
  <div class="layout">
    <div class="layout__main-content">
      <slot name="main-content"></slot>
    </div>
    <div class="layout__sidebar">
      <div class="layout__sidebar-top">
        <slot name="additional-top-sidebar"></slot>
      </div>
      <slot name="sidebar">
        <Sidebar v-if="!isTestingEnvironment && isSideBarVisible" />
      </slot>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar/Sidebar.vue";

export default {
  name: "ContentSidebarLayout",
  components: { Sidebar },
  props: {
    isSideBarVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isTestingEnvironment: process.env.NODE_ENV === "testing",
    };
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_resolutions.scss";

/** @define layout; */
.layout {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
  box-sizing: border-box;
  padding: 0 0.5em;

  @include laptop {
    padding-top: 2.5em;
  }
}

.layout__main-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include laptop {
    width: 65%;
    height: 100%;
  }
}

.layout__sidebar {
  display: none;

  @include laptop {
    width: 35%;
    display: inline;
    padding: 0 15px;
  }
}
</style>
