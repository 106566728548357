var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-major"},[_c('ContentSidebarLayout',[_c('div',{staticClass:"main-content",attrs:{"slot":"main-content"},slot:"main-content"},[_c('div',{staticClass:"main-content__school-img"},[_c('SchoolImage')],1),_c('div',{staticClass:"main-content__input-drop-down"},[_c('InputDropdown',{attrs:{"items":_vm.items,"item-template":_vm.baseItem,"placeholder":_vm.placeholder,"loading":_vm.loadingItems},on:{"changed":_vm.inputChange,"selected":_vm.selectItem,"no-results":function($event){_vm.resultsAvailable = false}},model:{value:(_vm.inputModel),callback:function ($$v) {_vm.inputModel=$$v},expression:"inputModel"}})],1),(_vm.searchStarted && !_vm.resultsAvailable)?_c('div',{staticClass:"main-content__no_results",attrs:{"data-test-id":"no-results"}},[_vm._v(" No encontramos ninguna coincidencia ")]):_vm._e(),_c('div',{staticClass:"main-content__help_results"},[_vm._v(" Puedes intentar con palabras como: ipn, fes, unam, cecyt, preparatoria, Ingeniería, Licenciatura ")]),_c('div',{staticClass:"main-content__caption"},[_c('div',{staticClass:"caption__title"},[_vm._v("¿Aún no te decides?")]),_c('span',[_vm._v(" Recuerda que puedes añadir otras opciones en cualquier momento. ")])]),_c('div',{staticClass:"main-content__btn"},[_c('BaseButton',{attrs:{"style-modifier":"secondary","is-disabled":_vm.selectedItemId === null},on:{"click":function($event){return _vm.$router.push({
              name: 'RegisterAsAStudentPage',
              params: {
                desiredMajorId: _vm.selectedItemId,
                desiredMajor: _vm.selectedItem,
              },
            })}}},[_vm._v(" Siguiente ")]),_c('div',{staticClass:"main-content__contact_container"},[_c('div',{staticClass:"main-content__contact_text1"},[_vm._v("¿No encuentras tu escuela?")]),_c('div',{staticClass:"main-content__contact_text2"},[_vm._v("¡Contáctanos!")]),_c('div',{staticClass:"main-content__contact_icons"},[_c('div',{attrs:{"data-test-id":"facebook-btn"},on:{"click":_vm.redirectToMessengerChat}},[_c('img',{staticClass:"main-content__icon-size",attrs:{"src":require('@/assets/facebook-logo.png'),"alt":"Facebook"}})]),_c('div',{attrs:{"data-test-id":"instagram-btn"},on:{"click":_vm.redirectToInstagramChat}},[_c('img',{staticClass:"main-content__icon-size",attrs:{"src":require('@/assets/instagram-logo.png'),"alt":"Instagram"}})])])])],1)]),_c('div',{attrs:{"slot":"sidebar"},slot:"sidebar"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }