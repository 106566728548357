<template>
  <div>
    <ContentSidebarLayout>
      <div slot="main-content" class="main-add-courses">
        <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
        <div class="previous-btn-courses" data-test-id="back-courses" @click="onClickBackCourses">
          <BaseButton style-modifier="previous-item" class="add-courses__previous-btn">
            <PreviousButtonArrowImage class="previous-btn__img" />REGRESAR</BaseButton
          >
        </div>
        <p class="add-courses-text">Añadir Curso</p>
        <div class="add-courses__school-img">
          <SchoolImage />
        </div>
        <div class="add-courses__input-drop-down">
          <InputDropdown
            v-model="inputModel"
            data-test-id="equals-course-drop"
            :items="items"
            :item-template="baseItem"
            :placeholder="placeholder"
            :loading="loadingItems"
            @changed="inputChange"
            @selected="selectItem"
          />
        </div>
        <div>
          <div v-show="isEqualsCourseVisible" class="equals-course" data-test-id="equals-course">
            <p>Ya estas inscrito al curso seleccionado</p>
          </div>
        </div>
        <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
        <div data-test-id="add-course-button" class="add-courses__btn" @click="onClickCourses">
          <BaseButton style-modifier="secondary" :is-disabled="selectedItemId === null">
            <span>Añadir Nuevo Curso (50 <CoinsImage class="coins-img__img" />)</span>
          </BaseButton>
        </div>

        <CoursePriceModal
          v-model="isPriceModalComponentVisible"
          data-test-id="course-price-modal"
          @continue-with-purchase-course="addCourse"
        />
        <InsufficientCoinsModalComponent
          v-model="isInsufficientCoinsModalVisible"
          data-test-id="insufficient-coins-modal"
        />
      </div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";
import SchoolImage from "@/assets/school.svg?inline";
import CoinsImage from "@/assets/coins.svg?inline";
import InputDropdown from "@/components/input_dropdown/InputDropdown.vue";
import BaseItem from "@/components/input_dropdown/BaseItem.vue";
import SearchMajor from "@/use_cases/select_major/SearchMajor";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import PreviousButtonArrowImage from "@/assets/collapse-arrow.svg?inline";
import CoursePriceModal from "@/use_cases/profile/courses/CoursePriceModalComponent.vue";
import InsufficientCoinsModalComponent from "@/components/InsufficientCoinsModalComponent.vue";
import InsufficientCoins from "@/exceptions/InsufficientCoins";
import AddCourse from "@/use_cases/profile/courses/AddCourse";
import DesiredMajorHasAlreadyBeenSelected from "@/exceptions/DesiredMajorHasAlreadyBeenSelected";

export default {
  name: "AddCoursePage",
  components: {
    InsufficientCoinsModalComponent,
    CoursePriceModal,
    BaseButton,
    SchoolImage,
    InputDropdown,
    ContentSidebarLayout,
    PreviousButtonArrowImage,
    CoinsImage,
  },
  data() {
    return {
      baseItem: BaseItem,
      inputModel: "",
      placeholder: "Busca tu escuela/carrera deseada",
      searchMajorServiceRequest: "",
      searchMajorService: "",
      searchMajorResponse: "",
      minLength: 3,
      rootSearchTextChanged: true,
      items: [],
      loadingItems: false,
      selectedItem: null,
      selectedItemId: null,
      isPriceModalComponentVisible: false,
      isInsufficientCoinsModalVisible: false,
      isEqualsCourseVisible: false,
      courses: [],
    };
  },
  mounted() {
    this.$emit("load-finished");
  },
  methods: {
    async searchMajor() {
      this.items = await SearchMajor.getMajors(this.inputModel);
    },
    async inputChange(inputModel, validString) {
      this.selectedItem = null;
      this.selectedItemId = null;
      this.rootSearchTextChanged = inputModel.length > this.inputModel.length;
      this.inputModel = inputModel;
      if (inputModel.length === this.minLength && this.rootSearchTextChanged && validString) {
        this.loadingItems = true;
        await this.searchMajor();
        this.loadingItems = false;
      }
    },
    selectItem(item, id) {
      this.selectedItem = item;
      this.selectedItemId = id;
      this.isEqualsCourseVisible = false;
    },
    async addCourse() {
      try {
        await AddCourse.addCourse({ idDesiredMajor: this.selectedItemId });
        await this.$router.push({ name: "ProfilePage" });
      } catch (e) {
        if (e instanceof InsufficientCoins) {
          this.isInsufficientCoinsModalVisible = true;
        }
        if (e instanceof DesiredMajorHasAlreadyBeenSelected) {
          this.isEqualsCourseVisible = true;
        }
      }
    },
    async onClickBackCourses() {
      await this.$router.push({ name: "ProfilePage" });
    },
    async onClickCourses() {
      this.isPriceModalComponentVisible = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

.main-add-courses {
  @extend %font-form;

  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-left: 1.8em;
  margin-right: 1.8em;
}

.add-courses__school-img {
  width: 80%;
  margin: 3em;

  @include laptop {
    width: 50%;
    margin: 1em;
    padding-top: 3em;
  }
}

.add-courses__input-drop-down {
  width: 90%;

  @include laptop {
    width: 60%;
    padding-top: 1em;
  }
}

.add-courses__btn {
  flex-grow: 1;
  width: 100%;
  margin-top: 3em;

  @include laptop {
    width: 70%;
  }
}

.add-courses__previous-btn {
  color: $primary-color;
}

.previous-btn__img {
  transform: rotate(90deg);
  width: 8%;

  @include laptop {
    width: 25%;
  }
}

.previous-btn-courses {
  margin-left: -10em;
  margin-right: auto;

  @include laptop {
    margin-left: -3em;
    margin-right: auto;
    margin-top: 2em;
  }
}

.previous-btn__img > path {
  fill: $primary-color;
}

.add-courses-text {
  color: $title-color;

  @extend %font-title-1;
}

.coins-img__img {
  width: 1.2em;
}

.equals-course {
  color: red;
  text-align: left;
  margin-bottom: -2rem;
  margin-top: -1rem;
}
</style>
