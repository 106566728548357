<template>
  <BaseModal
    :is-visible="isVisible"
    :is-close-button-visible="true"
    :is-close-button-disabled="isDisabledIssuesForm"
    style-modifier="transparent"
    @hide-modal="handleHideModal"
  >
    <div slot="message" class="send-feedback-modal-container">
      <p class="send-feedback-modal_title">Agradecemos tus comentarios sobre la app</p>
      <BaseInputText
        v-model="feedbackText"
        placeholder="Escribe tus comentarios / sugerencias"
        type="text"
        :disabled="isDisabledIssuesForm"
        data-test-id="input-text"
      ></BaseInputText>
      <BaseButton
        style-modifier="secondary"
        class="send-feedback-modal_btn"
        :is-disabled="!isButtonDisabled"
        :is-loading="isLoadingBtn"
        data-test-id="send-feedback-question-button"
        @click="handleSendReportIssue"
      >
        Enviar
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import BaseInputText from "@/components/base_input_text/BaseInputText.vue";
import SendFeedback from "@/services/send_feedback/SendFeedback";

export default {
  name: "AppFeedbackModal",
  components: { BaseInputText, BaseButton, BaseModal },
  model: {
    prop: "isVisible",
    event: "hide-modal",
  },
  props: {
    isCloseButtonVisible: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      isLoadingBtn: false,
      feedbackText: "",
    };
  },
  computed: {
    isButtonDisabled() {
      return this.feedbackText.length > 0;
    },
  },
  methods: {
    async handleSendReportIssue() {
      try {
        this.isLoadingBtn = true;
        await SendFeedback.sendFeedback({
          text: this.feedbackText,
          client: "web",
        });
        this.resetForm();
        this.$emit("send-feedback");
      } catch (error) {
        throw Error(error);
      } finally {
        this.resetForm();
      }
    },
    async handleHideModal() {
      this.$emit("hide-modal");
      this.resetForm();
    },
    resetForm() {
      this.feedbackText = "";
      this.isLoadingBtn = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/typography";
@import "~@/scss/colors";

.send-feedback-modal-container {
  background-color: $white;
  border-radius: 0.25em;
  color: $black;
  padding: 1.25em;
  text-align: left;
}

.send-feedback-modal_title {
  @extend %font-subtitle-1;

  text-align: center;
}

.send-feedback-modal_btn {
  padding-top: 1em;
}

.send-feedback-modal_checkbox {
  @extend %font-body-1;

  line-height: 1;
  padding-top: 1.5em;
  display: flex;
  align-items: center;
}

.send-feedback-modal_checkbox input {
  margin-right: 1em;
}

.send-feedback-modal_checkbox label {
  flex: 1;
  white-space: normal;
}

.custom-checkbox {
  width: 1.5em;
  height: 1.5em;
  appearance: none;
  border: 2px solid $gray-bolder;
  border-radius: 0.3em;
  cursor: pointer;
  background-color: $white;
}

.custom-checkbox:checked {
  background-color: $primary-color;
  border: none;
}

.custom-checkbox:checked::after {
  content: "\2713";
  font-size: 1em;
  color: $white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
